
import { onMounted, onBeforeUnmount, ref, watch, computed, nextTick, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Footer from "@/components/footer.vue"; // @ is an alias to /src
const route = useRoute();
const router = useRouter();

export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
  setup(props) {
    const { proxy } = getCurrentInstance() as any;
    const { $utils } = getCurrentInstance()!.appContext.config.globalProperties || {};

    let scrollTop = ref<number>(0); // 记录当前的滚动距离
    let mobileTop = ref<number>(0); // 记录当前的滚动距离

    let header = ref(null);
    let fixedTurn = ref(false);
    let offsetHeight = ref(document.getElementById("homeBanner")?.offsetHeight || 0);
    let absOffsetHeight = ref(document.getElementById("homeBanner")?.offsetHeight || 0);

    let nowRoute = ref(location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1]);
    if (scrollTop.value >= 100) {
      nowRoute.value = "other";
    } else if (route && route.path) {
      nowRoute.value = route.path.split("/")[1];
    }
    let scrollTurn = computed(() => {
      let top = 0;
      if ($utils.isMobile()) {
        return scrollTop.value > 1;
      } else {
        return scrollTop.value > 0;
      }
    });
    let pathName = computed(() => {
      return location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
    });

    function handleScroll() {
      if ($utils.isMobile()) {
        let el = document.getElementById("app") as HTMLElement;
        el.addEventListener("scroll", () => {
          scrollTop.value = el.scrollTop;
        });
        // el.addEventListener("touchmove", (event) => {
        //   scrollTop.value = el.scrollTop;
        //   console.log(event, el.scrollTop);
        //   let element = document.getElementsByClassName("phone")[0] as HTMLElement;
        //   let ClientRect = element.getBoundingClientRect();
        //   mobileTop.value = Math.floor(element.offsetTop - ClientRect.top);
        // });
      } else {
        window.addEventListener("scroll", () => {
          scrollTop.value = window.scrollY;
        });
      }
    }

    let updateScroll = (res) => {
      nextTick(() => {
        if (res != 0) {
          scrollTop.value = window.scrollY;
        }
        nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
        if (res == 1) {
          setTimeout(() => {
            nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
          }, 80);
        }
      });
    };
    nextTick(() => {
      watch(
        () => scrollTop.value,
        (newValue, oldValue) => {
          updateScroll(0);
        }
      );
    });
    onMounted(() => {
      handleScroll();
      nowRoute.value = location.hash.split("?")[0].split("/")[location.hash.split("?")[0].split("/").length - 1];
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", () => null); // 离开当前组件别忘记移除事件监听
    });
    return { scrollTop, nowRoute, updateScroll, fixedTurn, scrollTurn, pathName, header, offsetHeight, absOffsetHeight, $utils };
  },
};
