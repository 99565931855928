import { resolveComponent as _resolveComponent, createVNode as _createVNode, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_KeepAlive, null, [
      _createVNode(_component_Header, {
        ref: "header",
        activeRoute: $setup.nowRoute,
        scrollTurn: $setup.scrollTurn,
        onUpdateScroll: $setup.updateScroll
      }, null, 8, ["activeRoute", "scrollTurn", "onUpdateScroll"])
    ], 1024)),
    _createVNode(_component_router_view),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      _createVNode(_component_Footer)
    ], 1024))
  ]))
}