
let throttleTimeKey = null
export default {

  /**
   * 邮箱 正则验证
   **/
  mailCheck(num) {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(num);
  },
  /**
   * 固话 正则验证 010-22223333
   **/
  homeTelCheck(num) {
    return /^\d{3}-\d{8}|\d{3}-\d{7}|\d{4}-\d{7}|\d{4}-\d{8}/.test(num);
  },

  /**
   * 手机 正则验证
   **/
  telCheck(num) {
    return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(parseInt(num));
  },
  /**
   * 银行卡 正则验证
   **/
  bankcardCheck(num) {
    return /^([1-9]{1})(\d{15}|\d{18})$/.test(parseInt(num));
  },
  /**
   * 车牌号 正则验证
   **/
  carNumCheck(num) {
    return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}/.test(num);
  },
  /**
   * qq号 正则验证
   **/
  qqCheck(num) {
    return /^[1-9][0-9]{4,9}$/gim.test(num);
  },

  judgeData(data) {
    let judge;
    if (Object.prototype.toString.call(data) === "[object Number]") {
      judge = !!data;
    } else if (Object.prototype.toString.call(data) === "[object Boolean]") {
      judge = data;
    } else if (Object.prototype.toString.call(data) === "[object Object]" && Object.values(data).length > 0) {
      judge = true;
    } else if ((Object.prototype.toString.call(data) === "[object Array]" || Object.prototype.toString.call(data) === "[object String]") && data.length > 0) {
      judge = true;
    } else {
      judge = false;
    }
    return judge;
  },

  throttle(fn, wait = 200, scope) {
    clearTimeout(throttleTimeKey);
    throttleTimeKey = setTimeout(function () {
      fn.apply(scope);
    }, wait);
  },
  getNavigator() {
    // 判断是否为手机
    const flag = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    if (flag) {
      return "phone"
      // 手机设备适配代码
    } else {
      return "PC"
      // PC设备适配代码
    }
  },
  isMobile() {
    const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag
    // if (flag) {
    //   return "phone"
    //   // 手机设备适配代码
    // } else {
    //   return "PC"
    //   // PC设备适配代码
    // }
  }
};
