let apiBaseUrl = ""
if (process.env.NODE_ENV === 'development') {
  apiBaseUrl = "/api"
} else {
  // apiBaseUrl = "/"
  // 生产环境
  // apiBaseUrl = 'https://dev.zecaimao.cn//'
  apiBaseUrl = window.location.protocol + '//' + window.location.host
}
// apiBaseUrl ='http://.zecaimao.cn'
export default apiBaseUrl;
